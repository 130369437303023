import React, { Component } from 'react';

import SignPage from './SignPage';
import SignContrat from './SignContrat';
import api from '../../api';
import notifSystem from '../../notifSystem';
import withRouter from '../Hoc/withRouter';

const Components = ({ type, ...props }) => ({
  'artisans': <SignContrat {...props}/>,
  [type === 'devis' ? 'devis' : 'interventions']: <SignPage {...props} />,
  '': null
})[type];

class SignPages extends Component {
  state = {
    collection: '',
    data: {}
  }
  componentDidMount() {
    const { type: collection } = this.props.params || {};
    return api.signature.custom('checkDocument')
      .get({ collection })
      .then(e => {
        const data = e.body().data() || {};
        this.setState({
          data,
          collection
        });
      })
      .catch(() => {
        notifSystem.error('Erreur', 'Donnée invalid');
      });
  }


  render () {
    const { data, collection } = this.state;
    return <Components
      type={collection}
      collection={collection}
      data={data}
      navigate={this.props.navigate}
    />;

  }
}

export default withRouter(SignPages);
