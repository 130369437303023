import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../actions/dialog';
import { paths } from '../../actions/proposition';
import { CancelStep, ReprogramStep } from '../Proposition/Steps';

const setData = setDialogContentProp('data');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      merge,
      data,
      auth
    },
  },
}) => ({
  merge,
  data,
  auth
});

const mapDispatchToProps = {
  setData,
};

const fetchComponent = props => [
  <ReprogramStep key={'reprog'} {...props} />,
  <CancelStep key={'cancel'} {...props} />,
];

const UpdateStatusDialog = ({
  merge, setData, data, auth
}) => {
  const [current, setCurrent] = useState(merge);
  const updateData = (path, value) => {
    path = path instanceof Array ? path : [path];
    const nData = data.setIn(path, value);
    setCurrent((oCurr) => oCurr.setIn(path, value));
    setData(nData);
  };
  const component = fetchComponent(
    {
      merge: current,
      setData: updateData,
      auth
    }
  )[merge.getIn(paths.status) - 2];

  return component;
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(UpdateStatusDialog);
