import React, { useEffect, useRef, useState } from 'react';
import '../../mapbox-gl.css';
import keys from '../../constants/keys';
import MapGL, {
  NavigationControl,
  FlyToInterpolator
} from 'react-map-gl';
import { makeStyles } from 'tss-react/mui';
import { Button, Grid, Typography } from '@mui/material';

const MAPBOX_TOKEN = keys.mapbox;
let counter = 0;

const useStyles = makeStyles()({
  artisanImg: {
    width: '35px',
    height: '35px',
    marginLeft: '-8px',
    marginTop: '-10px'
  },
  clientImg: {
    width: '35px',
    height: '35px',
    marginLeft: '-8px',
    marginTop: '-10px'
  },
  pointTitle: {
    marginLeft: '-25px',
  },
  buttonGoTo: {
    marginTop: '10px',
    marginLeft: '10px',
    zIndex: 100
  },
  grid: {
    height: '90%',
  },
});

const Map = (props) => {
  const defaultState = {
    newArtisanPoint: {
      type: 'Point',
      coordinates: props?.normalRoute[0] || [],
      properties: {
        title: 'Artisan'
      }
    },
    newLine: {
      type: 'Feature',
      geometry: {
        coordinates: props?.normalRoute || [],
        type: 'LineString'
      }
    }
  };
  const [state, setState] = useState(defaultState);
  const [viewport, setViewport] = useState({
    longitude: state.newArtisanPoint.coordinates[0],
    latitude: state.newArtisanPoint.coordinates[1],
    zoom: 15,
  });
  const { classes } = useStyles();
  const mapRef = useRef();

  const goToArtisan = () => {
    setViewport({
      ...viewport,
      longitude: state.newArtisanPoint.coordinates[0],
      latitude: state.newArtisanPoint.coordinates[1],
      zoom: 11.5,
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator(),
    });
  };

  useEffect(() => {
    const { artisanRoute, normalRoute, setData } = props;
    let animation;
    let interval;
    if (artisanRoute.length && normalRoute.length && props.moveMarker) {
      counter = 0;
      const line = artisanRoute.concat(normalRoute);
      interval = setInterval(() => {
        if (counter >= artisanRoute.length) {
          counter = 0;
          return clearInterval(interval);
        }
        animation = window.requestAnimationFrame(() => {
          const marker = props.moveMarker({ line, artisanRoute, counter });
          counter++;
          setData({
            newArtisanPoint: marker.newArtisanPoint.coordinates,
            loading: false
          });
          return setState(marker);
        }
        );
      }, 3000);
    } else if (!artisanRoute.length && normalRoute.length) {
      setState({
        newArtisanPoint: {
          type: 'Point',
          coordinates: props?.normalRoute[0] || [],
          properties: {
            title: 'Artisan'
          }
        },
        newLine: {
          type: 'Feature',
          geometry: {
            coordinates: props?.normalRoute || [],
            type: 'LineString'
          }
        }
      });
    }
    return () => {
      clearInterval(interval);
      window.cancelAnimationFrame(animation);
    };
  }, [props.normalRoute, props.artisanRoute]);

  const { width, height, genMarkers, error } = props;
  return (
    <div
      style={{
        width: width ? width : '100%',
        height: height ? height : '100%',
      }}
    >
      {!error ? <MapGL
        {...viewport}
        ref={mapRef}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/streets-v9?optimize=true"
        onViewportChange={newViewport => {
          setViewport({ ...newViewport });
        }}
        mapboxApiAccessToken={MAPBOX_TOKEN}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonGoTo}
          onClick={goToArtisan}
          children={'Position de l\'artisan'}
        />
        <NavigationControl style={{ right: 10, top: 10 }} />
        {genMarkers && genMarkers({ props, classes, state })}
      </MapGL> :
        <Grid
          className={classes.grid}
          container
          spacing={2}
          justify="center"
          alignContent="center"
        >
          <Grid
            item
            xs={12}
            lg={12}
            sm={12}
            children={
              <Typography
                align="center"
                variant="h3"
                children={error}
              />
            }
          />
        </Grid>
      }
    </div>
  );
};

export default Map;
