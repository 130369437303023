import { setDialog } from '../dialog';
import { signalInformation } from './api';
import store from '../../store';
import { paths, sendPropositionApi } from '../proposition';
import {
  P_ANN,
  P_RPROG,
} from '@lba-dev/package.local-globals/propositionStatus';
import notifSystem from '../../notifSystem';
import { Map } from 'immutable';

export function signalInformationDialog() {
  return store.dispatch(setDialog({
    name: 'SendSignal',
    open: true,
    dialogProps: {
      title: 'Comment souhaitez vous nous joindre ?',
      middle: true,
      fullScreenOn: false
    },
    actions: [
      {
        children: 'Valider',
        onClick: ({ comments }, close) =>
          signalInformation(comments, close)
      },
    ],
  }));
}

export function informNewVersion() {
  return store.dispatch(
    setDialog({
      name: 'VerficationProp',
      open: true,
      hideClose: true,
      dialogProps: {
        title: 'Information',
        middle: true,
        fullScreenOn: false,
      },
      contentProps: {
        message:
          'Une nouvelle version est disponible, ' +
          'Mise à jour en cours . Veuillez patienter',
      },
    })
  );
}

const updateState = (setData, data, isCancel) => Promise.all(isCancel ? [
  setData(paths.cancelRaison, data.getIn(paths.cancelRaison)),
] : [
  setData(paths.reprogDate, data.getIn(paths.reprogDate)),
  setData(
    paths.clientHasInformed,
    data.getIn(paths.clientHasInformed)
  )
]);

const checkData = (data, status) => {
  if (status === P_ANN && !data.hasIn(paths.cancelRaison)) {
    notifSystem.error(
      'Champs incomplets', 'Veuillez préciser une raison'
    );
    return false;
  }
  if (status !== P_ANN && (!data.hasIn(paths.reprogDate) ||
      !data.hasIn(paths.clientHasInformed))) {
    notifSystem.error(
      'Champs incomplets', 'Veuillez remplir tout les champs'
    );
    return false;
  }
  return true;
};

export function openUpdateStatusInter({
  merge,
  updateInterDate,
  setData,
  sendProposition,
  navigate,
  auth
}) {
  const status = merge.getIn(paths.status);
  return store.dispatch(
    setDialog({
      name: 'UpdateStatusDialog',
      open: true,
      hideClose: true,
      dialogProps: {
        title:
          (status === P_RPROG &&
            'Vous souhaitez reprogrammer le rendez-vous') ||
          (status === P_ANN && 'Veuillez sélectionner la raison d\'annulation'),
      },
      contentProps: {
        merge,
        setData,
        data: new Map(),
        auth
      },
      actions: [
        {
          children: 'Retour',
          variant: 'contained',
          style: { marginLeft: 16 },
          fullWidth: true,
          onClick: (r, close) => {
            setData(paths.status, '');
            close();
          },
        },
        {
          children: 'Valider',
          fullWidth: true,
          variant: 'contained',
          style: { marginRight: 16 },
          color: 'primary',
          onClick: async (r, close) => {
            const isCancel = status === P_ANN;
            if (checkData(r.data, status)) {
              await updateState(setData, r.data, isCancel);
              if (isCancel) {
                return sendProposition(
                  'cancel',
                  (...arg) =>
                    sendPropositionApi(...arg).then(() => {
                      close();
                      if (navigate) {
                        navigate('/missions/news');
                      }
                    }),
                  'inter',
                  {
                    cancelReason: r.data.getIn(paths.cancelRaison, ''),
                    comments: r.data.getIn(paths.propComments, ''),
                  }
                );
              }
              updateInterDate();
              close();
            }
          },
        },
      ],
    })
  );
}
