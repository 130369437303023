/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import { Map } from 'immutable';
import MoneyInput from '../../CustomInputs/MoneyInput';
import CustomSelect from '../../CustomInputs/CustomSelect';
import {
  CHEQUE,
  ESP,
  CB,
  data as paymentMethodsData,
} from '@lba-dev/package.local-globals/paymentMethods';
import CustomRadioGroup from '../../CustomInputs/CustomRadioGroup';
import CustomTextField from '../../CustomInputs/CustomTextField';
import PropositionUploadButton from '../PropositionUploadButton';
import {
  DFARTI,
  DBON,
  DCHQ,
  DIARTI,
} from '@lba-dev/package.local-globals/docTypes';
import {
  sendPropositionApi,
  invoiceClient,
  invoiceMaterial,
  paths,
  paiementDialog,
  sendPaymentCBArtisanClient,
} from '../../../actions/proposition';
import Money from '../../../utils/Money';
import InfoIcon from '@mui/icons-material/Info';
import {
  P_ACCEPTED,
  P_NONE,
} from '@lba-dev/package.local-globals/isValidStatus';
import { I_VRF } from '@lba-dev/package.local-globals/iStatus';
import withMediaQuery from '../../Hoc/WithMediaQuery';
import notifSystem from '../../../notifSystem';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  L_ADVD,
  L_PAYD,
} from '@lba-dev/package.local-globals/paymentCBActionTypes';
import { green, red } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import { Box } from '@mui/material';
import withRouter from '../../../components/Hoc/withRouter';
import { MAX_PRICE_TO_SEND_PAYMENT } from '../../../utils/number';
import { TVA_A } from '@lba-dev/package.local-globals/tvaStatus';

const mapStateToProps = (state) => ({
  auth: state.auth.decodedToken,
});

const errorHandling = {
  description: {
    min: 2,
    message: 'Description (2 caractères minimum)',
    condition: (verification, proposition) =>
      verification &&
      proposition.get('descriptionMat', '').length <
        errorHandling.description.min &&
      proposition.get('suppliesPrice') <= 2000,
  },
};

const textFourniture =
  'Si le prix d\'achat est supérieur à 20€, ' +
  'merci de joindre une facture de votre fournisseur';

const setMatReturned = ({ setData, value, path }) => {
  setData(path, Number(value));
  setData(paths.suppliesPrice, +value ? null : 0);
  setData(paths.descriptionMat, '');
};

const selectFour = [
  { label: 'Oui', value: '1' },
  { label: 'Non', value: '0' },
];

export const callsendPaymentCB = async ({
  setData,
  merge,
  conditionPayement,
  auth,
  sendTo,
  pourcent,
  mailTo,
  telTo,
  type = L_PAYD,
}) => {
  let mail = mailTo || auth.email1 || '';
  let tel = telTo || auth.tel1 || '';
  let to = sendTo || 'artisan';

  if (conditionPayement) {
    mail = merge.getIn(['client', 'mail'], '');
    tel = merge.getIn(['client', 'tel'], '');
    to = 'client';
  }
  const data = {
    setData,
    artisanId: auth.id,
    mail,
    tel,
    montant: type !== L_ADVD ? merge.getIn(paths.priceTtc) : '',
    id: merge.getIn(['id'], ''),
    to,
    merge,
    type,
    ...(pourcent && {
      pourcent,
    }),
  };
  if (conditionPayement) {
    paiementDialog(data);
  } else {
    await sendPaymentCBArtisanClient(data);
  }
};

const send = (props, dropbox) => {
  const { sendProposition, merge, setData, deleteDoc, verificationUpdate } =
    props;
  verificationUpdate(false);
  const data = {
    newPrice: merge.getIn(paths.propsFinalPrice, paths.finalPrice),
    matReturned: !!merge.getIn(paths.matReturned),
    tva: merge.getIn(paths.tva),
    paymentMethod: merge.getIn(paths.paymentMethod),
    devisToDo: !!merge.getIn(paths.devisToDo, 0),
    descriptionMat: merge.getIn(paths.descriptionMat),
    suppliesPrice: merge.getIn(paths.suppliesPrice),
    comments: merge.getIn(paths.propComments),
  };
  if (dropbox.filter((e) => e.type === DBON).length) {
    return sendProposition('finish', sendPropositionApi, 'inter', data);
  }
  return invoiceClient({
    merge,
    deleteDoc,
    setData,
    sendProposition,
    action: 'finish',
    data,
  });
};
const validate = (props) => {
  const { merge, setData, deleteDoc, verificationUpdate } = props;
  const dropbox = merge.getIn(paths.dropbox);
  if (merge.getIn(paths.matReturned, 0) === 1) {
    if (merge.getIn(paths.suppliesPrice, 0) <= 2000) {
      if (merge.getIn(paths.descriptionMat, '').length >= 2) {
        return send(props, dropbox);
      }
      verificationUpdate(true);
      return notifSystem.error(
        'Matériel fourni',
        'Veuillez fournir une description'
      );
    }

    if (dropbox.filter((e) => e.get('type') === DFARTI).size) {
      return send(props, dropbox);
    }
    return invoiceMaterial({ merge, deleteDoc, setData });
  }
  return send(props, dropbox);
};

const clientMailPhonePaymentCB = ({
  merge,
  paymentMethod,
  setData,
  auth,
  original,
  dropbox,
  deleteDoc,
  showCustomer,
}) => {
  if (![CHEQUE, ESP, CB].includes(paymentMethod)) {
    return;
  }
  const conditionPayement = paymentMethod === CB;
  const target = conditionPayement ? 'client' : 'artisan';
  const emailPath = [target, 'mail'],
    telPath = [target, 'tel'];
  const advancePayment = merge.getIn(paths.advancePayment, 0);
  const title = !conditionPayement
    ? 'M\'envoyer un lien de paiement'
    : 'Demande de paiement';
  const telClient = merge.getIn(telPath, '');
  return (
    <React.Fragment>
      {conditionPayement && showCustomer && [
        <Grid key={0} item xs={12} lg={6} sm={6}>
          <CustomTextField
            label="Mail client"
            type="email"
            value={merge.getIn(emailPath, '')}
            setData={setData}
            path={emailPath}
            fullWidth
          />
        </Grid>,
        <Grid key={1} item xs={12} lg={6} sm={4}>
          <CustomTextField
            label="Téléphone client"
            type="tel"
            value={
              merge.getIn(telPath, '') !== original.getIn(telPath, '')
                ? telClient
                : telClient.replace(/\d(?=\d{3})/g, 'X')
            }
            path={telPath}
            setData={(p, v) => setData(p, v.includes('X') ? '' : v)}
            fullWidth
          />
        </Grid>,
      ]}
      {[ESP, CB].includes(paymentMethod) ? (
        <Grid item container spacing={1} xs={12} lg={12} sm={12}
          id='paymentButtonGrid'>
          <Grid item xs={12}>
            <Button
              disabled={
                merge.getIn(paths.priceTtc) > MAX_PRICE_TO_SEND_PAYMENT &&
                !merge.getIn(paths.isFromDevis)
              }
              onClick={() =>
                callsendPaymentCB({
                  setData,
                  merge,
                  conditionPayement,
                  auth,
                })
              }
              fullWidth
              variant={'contained'}
              color="primary"
            >
              {title}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={8} sm={2} justifyContent="center">
          <PropositionUploadButton
            key={3}
            title="Ajouter le chèque"
            type={DCHQ}
            photos={dropbox}
            deleteFile={deleteDoc}
            setData={setData}
            id={merge.get('id')}
            statusColor={
              dropbox.filter((e) => e.get('type') === DCHQ).size
                ? green[500]
                : red[500]
            }
          />
        </Grid>
      )}
      {!!advancePayment && (
        <Grid
          item
          xs={12}
          lg={12}
          sm={2}
          container
          children={[
            <Grid
              key={0}
              item
              xs={0}
              children={
                <InfoIcon
                  fontSize="small"
                  color="action"
                  style={{ fontSize: 15 }}
                />
              }
            />,
            <Grid
              key={1}
              item
              xs
              display="flex"
              alignItems="center"
              children={
                <Typography
                  variant="caption"
                  children={
                    `Le client à déjà payé un acompte de ${Money.toString(
                      advancePayment
                    )}`}
                />
              }
            />,
          ]}
        />
      )}
    </React.Fragment>
  );
};

const updateInter = ({
  merge,
  paymentMethod,
  setData,
  auth,
  original,
  dropbox,
  deleteDoc,
  showCustomer,
  shouldDisplay
}) => {
  const [priceTtc, setPriceTtc] = useState(merge.getIn(paths.priceTtc));
  const finalPrice = merge.getIn(paths.propsFinalPrice) ||
    merge.getIn(paths.finalPrice);
  const tvaPrice = merge.getIn(paths.tva);
  useEffect(() => {
    const nPriceTtc = (finalPrice * (1 + (tvaPrice / 100))).toFixed(0);
    setPriceTtc(nPriceTtc);
  }, [tvaPrice, finalPrice]);

  const defaultTva = [5.5, 10, 20];
  const advancePayment = merge.getIn(paths.advancePayment, 0);
  const montant = advancePayment ? priceTtc - advancePayment : priceTtc;
  const setPrice = (p, v) =>
    setData(p, v,
      () =>
        setData(paths.priceTtc, (v * ((tvaPrice / 100) + 1)).toFixed(2))
    );


  const setTva = (p, v) =>
    setData(p, v,
      () =>
        setData(paths.priceTtc, +(finalPrice * ((v / 100) + 1)).toFixed(2))
    );
  return [
    shouldDisplay && <MoneyInput
      grid
      label={'Prix Final HT'}
      xs={8}
      gridProps={{ id: 'finalPriceGrid' }}
      type="number"
      defaultValue={finalPrice}
      path={paths.propsFinalPrice}
      setData={(p, v) => setPrice(p, v)}
      key={1}
    />,
    shouldDisplay && <CustomSelect
      key={2}
      grid
      xs={4}
      value={tvaPrice}
      path={paths.tva}
      setData={(p, v) => setTva(p, v)}
      label="TVA %"
    >
      {defaultTva.map((e) => (
        <MenuItem key={e} value={e}>
          {e}
        </MenuItem>
      ))}
    </CustomSelect>,
    <MoneyInput
      grid
      key={3}
      label={'Prix total T.T.C'}
      xs={12}
      disabled={true}
      type="number"
      defaultValue={priceTtc}
      path={[]}
      setData={(f) => f}
    />,
    !merge.get(paths.isRegle) &&
      clientMailPhonePaymentCB({
        merge,
        paymentMethod,
        setData,
        auth,
        original,
        dropbox,
        deleteDoc,
        showCustomer,
      }),
    shouldDisplay && advancePayment ? (
      <React.Fragment>
        <MoneyInput
          key={4}
          grid
          label={'Acompte'}
          xs={12}
          disabled={true}
          type="number"
          defaultValue={advancePayment}
          path={[]}
          setData={(f) => f}
        />
        <MoneyInput
          key={5}
          grid
          label={'Prix restant'}
          xs={12}
          disabled={true}
          type="number"
          defaultValue={montant}
          path={[]}
          setData={(f) => f}
        />
      </React.Fragment>
    ) : (
      ''
    ),
  ];
};

const FinishedStep = (props) => {
  const {
    merge,
    classes,
    setData,
    deleteDoc,
    title,
    original,
    verification,
    auth,
    navigate
  } = props;
  const proposition = merge.get('proposition', new Map());
  const paymentMethod = merge.getIn(paths.paymentMethod);
  const dropbox = merge.getIn(paths.dropbox);
  const descriptionCondition = errorHandling.description.condition(
    verification,
    proposition
  );
  const [showCustomer, setShowCustomer] =
    useState(!merge.getIn(paths.clientEmail1));
  const shouldDisplay = merge.getIn(paths.onSitePayment) &&
    !merge.getIn(paths.hidePrice) &&
    original.getIn(paths.interStatus) !== I_VRF &&
    ![P_ACCEPTED, P_NONE].includes(original.getIn(paths.isValid)) &&
    !original.getIn(paths.isRegle);
  return (
    !title && (
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10} sm={10}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            <PersonIcon fontSize="small" />
            <Typography variant="body1">
              {merge.getIn(['client', 'fullName'])}
            </Typography>
          </Box>
        </Grid>
        {shouldDisplay && <Grid item xs={2} sm={2}>
          <EditIcon
            fontSize="small"
            onClick={() => setShowCustomer(!showCustomer)}
          />
        </Grid>}
        {(shouldDisplay || merge.getIn(paths.paymentMethod) === ESP) && (
          <React.Fragment>
            {
              shouldDisplay && <CustomSelect
                grid
                xs={12}
                label="Mode de Paiement"
                value={merge.getIn(paths.paymentMethod)}
                path={paths.paymentMethod}
                setData={setData}
                gridProps={{ id: 'paymentGrid' }}
              >
                {paymentMethodsData
                  .slice(1, 4)
                  .map((e, i) =>
                    e.fullName ? (
                      <MenuItem key={i} value={e._id} children={e.fullName} />
                    ) : null
                  )}
              </CustomSelect>
            }
            {updateInter({
              merge,
              paymentMethod,
              setData,
              auth,
              original,
              dropbox,
              deleteDoc,
              showCustomer,
              shouldDisplay
            })}
          </React.Fragment>
        )}
        <Grid item xs={12} sm={12}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormLabel
                component="legend"
                children="Merci d'ajouter une photo des travaux réalisés"
              />
            </Grid>
            <Grid item xs={8} sm={2}>
              <PropositionUploadButton
                photos={merge.getIn(paths.dropbox)}
                deleteFile={
                  ![P_ACCEPTED, P_NONE].includes(
                    original.getIn(paths.isValid)) &&
                  deleteDoc
                }
                setData={setData}
                type={DIARTI}
                id={merge.get('id')}
                statusColor={
                  merge.getIn(paths.dropbox).filter(
                    (e) => e.get('type') === DIARTI).size
                    ? green[500]
                    : red[500]
                }
                title="Ajouter une photo"
              />
            </Grid>
          </Grid>
        </Grid>
        <CustomRadioGroup
          grid
          xs={12}
          sm={6}
          md={12}
          gridProps={{ id: 'matReturnedGrid' }}
          label="J'ai avancé du matériel"
          value={merge.getIn(paths.matReturned, '0').toString()}
          path={paths.matReturned}
          classes={classes}
          values={selectFour}
          setData={(path, value) => setMatReturned({ path, value, setData })}
        />
        {!!merge.getIn(paths.matReturned, 0) && [
          <>
            <CustomTextField
              key={8}
              grid
              xs={12}
              sm={12}
              error={descriptionCondition}
              label={
                !descriptionCondition
                  ? 'Description'
                  : errorHandling.description.message
              }
              path={paths.descriptionMat}
              value={merge.getIn(paths.descriptionMat, '')}
              setData={setData}
              fullWidth
            />
            <Grid item xs={12} sm={12} md={12} key={2}>
              <Typography variant="caption" gutterBottom>
                Veuillez fournir une description
              </Typography>
            </Grid>
          </>,
          <MoneyInput
            key={1}
            grid
            xs={12}
            sm={12}
            label={`Prix d'achat de la founiture ${auth.tva === TVA_A ?
              'HT' : 'TTC'}`}
            type="number"
            defaultValue={merge.getIn(paths.suppliesPrice, 0)}
            path={paths.suppliesPrice}
            setData={(p, v) => setData(p, v || null)}
          />,
          <Grid item xs={12} sm={12} md={12} key={2}>
            <Typography variant="caption" gutterBottom>
              {textFourniture}
            </Typography>
          </Grid>,
          <PropositionUploadButton
            key={3}
            title="Ajouter une facture"
            type={DFARTI}
            limit={4}
            photos={dropbox}
            deleteFile={deleteDoc}
            setData={setData}
            id={merge.get('id')}
            statusColor={
              dropbox.filter((e) => e.get('type') === DFARTI).size
                ? green[500]
                : red[500]
            }
          />,
        ]}
        <CustomRadioGroup
          grid
          xs={12}
          sm={6}
          md={12}
          gridProps={{ id: 'devisSupGrid' }}
          label="Il y a un devis supplémentaire à faire ?"
          value={merge.getIn(paths.devisToDo, '0').toString()}
          path={paths.devisToDo}
          classes={classes}
          values={[
            { label: 'Oui', value: '1' },
            { label: 'Non', value: '0' },
          ]}
          setData={(path, value) => setData(path, Number(value))}
        />
        {merge.getIn(paths.onSitePayment) && <Grid item xs={12} sm={12}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => navigate('/artisanDevis', {
              state: { data: merge.toJS() }
            })}
          >
            Créer un devis
          </Button>
        </Grid>}
        {
          merge.getIn(paths.onSitePayment) &&
          !merge.getIn(paths.isFromDevis) &&
          merge.getIn(paths.isValid) !== P_NONE && (
            <Grid item xs={12} sm={12}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12}>
                  <FormLabel
                    component="legend"
                    children="Merci de joindre la facture client."
                  />
                </Grid>
                <Grid item xs={8} sm={2}>
                  <PropositionUploadButton
                    key={3}
                    title="ajouter Facture Client"
                    type={DBON}
                    photos={dropbox}
                    deleteFile={deleteDoc}
                    setData={setData}
                    id={merge.get('id')}
                    statusColor={
                      dropbox.filter((e) => e.get('type') === DBON).size
                        ? green[500]
                        : red[500]
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        {merge.getIn(paths.isValid) !== P_NONE && (
          <CustomTextField
            key={8}
            grid
            xs={12}
            sm={12}
            label="Commentaire"
            path={paths.propComments}
            value={merge.getIn(paths.propComments, '')}
            setData={setData}
            fullWidth
          />
        )}
        {!props.isHistory && (
          <Grid item xs={6} sm={6}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={() => setData(['proposition', 'status'], '')}
            >
              Retour
            </Button>
          </Grid>
        )}
        <Grid item xs={6} sm={6}>
          <Button
            color="primary"
            size="medium"
            variant="contained"
            fullWidth
            onClick={() => validate(props)}
          >
            Valider
          </Button>
        </Grid>
      </Grid>
    )
  );
};

export default compose(
  connect(mapStateToProps),
  withMediaQuery(),
  withRouter
)(FinishedStep);
