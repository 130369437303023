import React from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';

import Map from './Map';

const styles = {
  contentClass: {
    height: '100%',
    width: '100%',
    marginTop: 0,
    position: 'relative'
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    zIndex: 2
  }
};

const Content = (props) => {
  const {
    classes,
    normalRoute,
    artisanRoute,
    genMarkers,
    moveMarker,
    setData,
    error
  } = props;
  return (
    <Grid key={1} className={classes.contentClass} style={{ height: '100%' }}>
      <Map
        height={'400px'}
        normalRoute={normalRoute}
        artisanRoute={artisanRoute}
        genMarkers={genMarkers}
        moveMarker={moveMarker}
        setData={setData}
        error={error}
      />
    </Grid>
  );
};

export default withStyles(Content, styles);
