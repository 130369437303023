import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

const withRouter = (WrappedComponent) => (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  return <WrappedComponent
    {...props}
    location={location}
    navigate={navigate}
    params={params}
  />;
};

export default withRouter;
