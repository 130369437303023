import restful, { fetchBackend } from 'restful.js';
import { setLoading } from './actions/loading';
import collections from './constants/collections';
import store from './store';

const api = restful(`${window.location.origin}/api`, fetchBackend(fetch));

api.login = api.custom(collections.login);
api.candidats = api.all(collections.candidats);
api.devis = api.all(collections.devis);
api.interventions = api.all(collections.interventions);
api.equipes = api.all(collections.equipes);
api.services = api.all(collections.services);
api.artisans = api.all(collections.artisans);
api.campagnes = api.all(collections.campagnes);
api.signature = api.custom(collections.signature);
api.paiements = api.all(collections.paiements);
api.propositions = api.all(collections.propositions);
api.metiersDevis = api.all(collections.metiersDevis);
api.count = api.all(collections.count);
api.dropbox = api.custom('dropbox');

const logRequest = ['login', 'verifToken'];

/**
 * event action for api call
 * @param {String} event - event name
 * 'request' => before api call
 * 'response' => after api call successful
 * 'error' => after error
 * @param {Object} config - request config (method, data ...)
 */
let timeoutId;
let responsesArr = [];

api.addRequestInterceptor((request) => {
  const { headers, ...config } = request;
  let withoutLoading = false;
  withoutLoading = config.method !== 'GET' &&
  !!config.data && config.data.withoutLoading;
  const timestamp = new Date().getTime();
  timeoutId = setTimeout(f =>
    withoutLoading
      ? f
      : store.dispatch(
        setLoading({
          open: true,
          message: logRequest.some(e => config.url.includes(e)) ?
            'Connexion en cours veuillez patienter' : ''
        })
      ));
  responsesArr.push({ timestamp, timeoutId, ...config });
  return {
    ...config,
    headers: { ...headers, timeoutId, timestamp }
  };
});

api.on('response', (t) => {
  const headers = t.headers();
  const index = responsesArr.findIndex(e =>
    e.timestamp === Number(headers.timestamp) &&
    e.timeoutId === Number(headers.timeoutid)
  );
  if (index > -1) {
    clearTimeout(responsesArr[index].timeoutId);
    responsesArr.splice(index, 1);
  } else {
    clearTimeout(responsesArr[responsesArr.length - 1].timeoutId);
    responsesArr.pop();
  }
  if (!responsesArr.length) {
    store.dispatch(setLoading({ open: false }));
  }
});

api.on('error', () => {
  responsesArr.map(e => clearTimeout(e.timeoutId));
  responsesArr.pop();
  store.dispatch(setLoading({ open: false }));
});

api.subscribe = (store) => {
  store.subscribe(() => {
    const state = store.getState();
    api.header('Authorization', state.token);
  });
};

export default api;
