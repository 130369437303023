import { red } from '@mui/material/colors';

export default defaultTheme => ({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#1b2150',
    },
    secondary: {
      main: red[500],
    },
  },
  typography: {
    body1: {
      fontSize: '0.875em',
    },
    fontFamily: [
      'Palanquin',
      'sans-serif'
    ].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary'
      }
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary'
      }
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary'
      }
    },
    MuiIconButton: {
      defaultProps: {
        size: 'large'
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'unset'
        }
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          paddingLeft: defaultTheme.spacing(1),
          paddingRight: defaultTheme.spacing(1),
          [defaultTheme.breakpoints.up('sm')]: {
            paddingLeft: defaultTheme.spacing(1),
            paddingRight: defaultTheme.spacing(1),
          },
          paddingTop: defaultTheme.spacing(2),
          paddingBottom: defaultTheme.spacing(2),
          marginLeft: defaultTheme.spacing(2.5),
          marginRight: defaultTheme.spacing(2.5),
          marginBottom: defaultTheme.spacing(2),
          marginTop: defaultTheme.spacing(2),
        },
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          borderTop: 0,
          paddingBottom: 0,
          '&:last-child': {
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 4,
            paddingRight: 4
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderTop: '2px solid darkblue',
        },
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '&.MuiDialogTitle-root': {
            color: '#1b2150',
          },
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: [
            'Palanquin',
            'sans-serif'
          ].join(','),
          '&$disabled': {
            background: 'rgba(0, 0, 0, 0.26)',
            border: 0
          }
        },
      }
    },
    MuiExpansionPanelSummary: {
      styleOverrides: {
        root: {
          borderTop: '1px solid #e2071459',
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #cecece',
        },
      }
    },
  },
});
