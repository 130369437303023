import api from '../api';
import React from 'react';
import { Marker, Source, Layer } from 'react-map-gl';
import Typography from '@mui/material/Typography';
import artisan from '../../public/artisan.png';
import redPin from '../../public/redPin.svg';

export const genMarkersList = ({ id }) =>
  api.interventions
    .custom(`gmapInfos/${id}`).get().then(e => e.body().data());


export const getUpdatedRoute = ({ travelMode, start, end, oldStart }) =>
  api.interventions
    .custom('getUpdatedRoute').get({
      travelMode,
      start,
      oldStart,
      end
    }).then(e => e.body().data());

export const moveMarker = ({ line, artisanRoute, counter }) => {
  let coord = {
    newArtisanPoint: {
      type: 'Point',
      coordinates: artisanRoute[counter],
      properties: {
        title: 'Artisan'
      }
    },
    newLine: {
      type: 'Feature',
      geometry: {
        coordinates: [...line],
        type: 'LineString'
      }
    }
  };
  if (artisanRoute[counter]
    !== artisanRoute.last()
    && counter < artisanRoute.length) {
    if (counter >= 1) {
      line.shift();
    }
    coord.newArtisanPoint.coordinates = artisanRoute[counter];
    coord.newLine.geometry.coordinates = [...line];
    return coord;
  }
  line.shift();
  coord.newArtisanPoint.coordinates = artisanRoute.last();
  coord.newLine.geometry.coordinates = line;
  return coord;
};

export const genMarkers = ({ props, classes, state }) => {
  const { newArtisanPoint, newLine } = state;
  return (
    <React.Fragment>
      <Source id='route' type='geojson' data={newLine} />
      <Layer
        id='route'
        type='line'
        source='route'
        layout={{
          'line-join': 'round',
          'line-cap': 'round'
        }}
        paint={{
          'line-color': '#5D97EB',
          'line-width': 7
        }}
      />
      <Marker
        latitude={newArtisanPoint?.coordinates[1] || 0}
        longitude={newArtisanPoint?.coordinates[0] || 0}
        offsetLeft={-15}
        offsetTop={-10}
      >
        <div>
          <img alt="artisan" className={classes.artisanImg} src={artisan} />
          <Typography variant="h5" className={classes.pointTitle}>Artisan
          </Typography>
        </div>
      </Marker>
      <Marker
        latitude={props?.normalRoute?.last()[1] || 0}
        longitude={props?.normalRoute?.last()[0] || 0}
        offsetLeft={-15}
        offsetTop={-10}
      >
        <div>
          <img alt="client" className={classes.clientImg} src={redPin} />
          <Typography variant="h5" className={classes.pointTitle}>Vous
          </Typography>
        </div>
      </Marker>
    </React.Fragment>
  );
};

export const genMarkersInfos = async ({ coordinates, params, stateRef }) => {
  let normalRoute, artisanRoute;
  const {
    markersList,
    artisanId,
    ...markersInfos
  } = await genMarkersList({ id: params.id });
  if ((!coordinates)
  || (coordinates && artisanId === coordinates.artisanId)
  ) {
    const artisanCoord = markersList.find(e =>
      e.properties.status === 'artisan').geometry.coordinates;
    const clientCoord = markersList.find(e =>
      e.properties.status === 'client').geometry.coordinates;
    if (artisanCoord && clientCoord) {
      ({ normalRoute, artisanRoute } = await getUpdatedRoute({
        travelMode: 'driving',
        oldStart: stateRef.current.newArtisanPoint.length ?
          stateRef.current.newArtisanPoint : coordinates?.oldPosition,
        start: artisanCoord,
        end: clientCoord
      }));
    } else {
      return {
        error: 'L\'artisan n\'est pas encore parti !',
        markers: markersList,
        loading: false,
        ...markersInfos };
    }
    const duration = normalRoute.routes[0].duration;
    return {
      markers: markersList,
      ...markersInfos,
      ...normalRoute && {
        normalRoute: normalRoute.routes[0].geometry.coordinates },
      ...artisanRoute && {
        artisanRoute: artisanRoute?.routes[0].geometry.coordinates },
      ...normalRoute && {
        distance: Math.round(normalRoute.routes[0].distance / 1000) },
      ...normalRoute && {
        duration: duration > 3600 ?
          `${Math.round(duration / 3600)} heure(s)`
          : `${Math.round(duration / 60)} minute(s)` },
      loading: false,
    };
  }
};


