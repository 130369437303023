import React from 'react';
import { withStyles } from 'tss-react/mui';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

const styles = {
  titleHeader: {
    padding: '0px!important',
  },
  titleCard: {},
  card: {},
  title: {
    lineHeight: '1.16'
  },
  titleContainer: {},
  thirdTitle: {
    color: '#fff',
    fontWeight: 700,
    padding: '10px',
  },
  noBorder: {
    padding: '0px 0px 10px',
    borderWidth: 0
  }
};

const MainLayout = ({
  classes,
  children,
  title,
  secondTitle = '',
  thirdTitle = '',
  childCardStyle = '',
  noTitleCard,
  onClick,
  centeredContent,
  statusColor,
  headerAction = null,
  withChildren = true,
}) =>
  (
    <React.Fragment>
      {!noTitleCard ? (
        <Card className={classes.titleCard}>
          <CardHeader
            className={classes.titleHeader}
            title={title}
            subheader={secondTitle}
            titleTypographyProps={{
              variant: 'h6',
              color: 'primary',
              className: classes.title,
              align: 'center',
            }}
          />
          <CardContent>{headerAction}</CardContent>
        </Card>
      ) : null}
      {withChildren && (
        <Card
          className={`${classes.card} ${statusColor ? classes.noBorder : null}`}
          onClick={onClick}
          style={{ childCardStyle }}
          elevation={2}
        >
          {thirdTitle ? (
            <Typography
              align="center"
              className={classes.thirdTitle}
              style={{ background: statusColor || null }}
            >
              {thirdTitle}
            </Typography>
          ) : (
            ''
          )}
          <CardContent
            className={classes.titleContainer}
            style={centeredContent ? { textAlign: 'center' } : null}
            children={children}
          />
        </Card>
      )}
    </React.Fragment>
  );

export default withStyles(MainLayout, styles);
