import React, { Component } from 'react';
import SignaturePad from 'react-signature-canvas';

import { withStyles } from 'tss-react/mui';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Send from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  C_NSIG, C_SIG
} from '@lba-dev/package.local-globals/caldeoStatus';

import api from '../../api';
import notifSystem from '../../notifSystem';

const styles = (theme) => ({
  container: {
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    height: 'calc(100% - 40px)',
    marginTop: 4
  },
  button: {
    'display': 'flex',
    'padding': 10
  },
  send: {
    'marginRight': theme.spacing(1),
  },
  option: {
    position: 'absolute',
    bottom: 18,
    right: 0,
    left: 0,
    'display': 'flex',
    'justifyContent': 'space-between',
    'margin': '0 20px',
  },
  canvasDiv: {
    height: 'calc(100% - 20px)',
    width: '100%',
    position: 'relative',
  },
  canvas: {
    height: '100%',
    width: '100%',
    border: '1px solid black',
    borderRadius: 15
  },
  margin: {
    margin: 10,
    height: 'calc(100% - 24px)'
  },
  label: {
    padding: 4,
    paddingLeft: 16,
  }
});

const labels = ({
  'artisans': 'Signature du contrat',
  'devis': 'Signature du dossier CALDEO',
  '': null
});

class SignPage extends Component {
  state = {
    dataURL: null,
    status: (this.props.data
      && this.props.data.signature
      && this.props.data.signature.status)
      || C_NSIG,
    loading: false,
    disableSend: true
  }

  sigPad = {}

  setLoading = value => this.setState({ loading: value })

  clear = () => {
    this.sigPad.clear();
    this.setState({ disableSend: true });
  }

  onBegin = () => this.setState({ disableSend: false })

  updateImage = () => {
    if (this.sigPad.isEmpty()) {
      return notifSystem.error('Erreur', 'Signature obligatoire');
    }
    const {
      collection,
      data = {},
      getSignature
    } = this.props;
    const file = this.sigPad.toDataURL();

    if (collection === 'artisans' && typeof getSignature === 'function') {
      return getSignature(file);
    }
    this.setLoading(true);
    api
      .signature
      .custom('docSign')
      .post({
        file,
        collection,
        id: data.id,
        fileName: `signature${data.id}`
      })
      .then(() => {
        notifSystem.success('Message', 'Signature envoyé');
        this.setState({ status: C_SIG });
      })
      .catch(() => notifSystem.error('Erreur', 'Signature non envoyée'))
      .finally(() => this.setLoading(false));
  }

  render() {
    const {
      classes,
      label = '',
      collection
    } = this.props;
    const {
      status,
      loading,
      disableSend
    } = this.state;

    return status === C_NSIG ? (
      <div className={classes.container}>
        {loading
          ? <CircularProgress />
          : (
            <div className={classes.canvasDiv}>
              <Typography
                variant='h5'
                fontWeight='bold'
                className={classes.label}
                children={label || labels[collection]}
              />
              <div className={classes.margin}>
                <SignaturePad
                  minWidth={2}
                  maxWidth={1.5}
                  onBegin={this.onBegin}
                  canvasProps={{ className: classes.canvas }}
                  ref={ref => {
                    this.sigPad = ref;
                  }}
                />
              </div>
              <div className={classes.option}>
                <Button
                  variant="extendedFab"
                  aria-label="Delete"
                  color="secondary"
                  onClick={this.clear}
                  className={classes.button}
                >
                  <DeleteIcon />
                  Effacer
                </Button>
                <Button
                  variant="extendedFab"
                  aria-label="Add"
                  color="primary"
                  disabled={disableSend}
                  onClick={this.updateImage}
                  className={classes.button}
                >
                  <Send className={classes.send} />
                  Envoyer
                </Button>
              </div>
            </div>
          )}
      </div>
    ) : (
      <Card style={{ textAlign: 'center', borderTopColor: 'darkblue' }}>
        <CardContent>
          <Typography
            variant="title"
            color="primary"
            align="center"
            children="Votre signature a bien été prise en compte"
          />
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(SignPage, styles);
