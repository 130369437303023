import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';

import Grid from '@mui/material/Grid';
import { Typography, Button } from '@mui/material';
import TimePicker from '../../TimePicker';
import { getTime, roundedTime } from '../../../utils/date';

import CustomRadioGroup from '../../CustomInputs/CustomRadioGroup';
import DatePicker from '../../DatePicker';
import withMediaQuery from '../../Hoc/WithMediaQuery';
import { paths, callClient } from '../../../actions/proposition';
import Phone from '@mui/icons-material/Phone';
import { openUpdateStatusInter } from '../../../actions/artisan/dialogs';

const styles = {
  formControl: {
    width: '100%',
    maxWidth: 500,
    marginTop: 16,
  },
  radioGroup: {
    flexDirection: 'row',
    justifyControl: 'space-around',
  },
  marginTop: {
    marginTop: 16,
  },
};

class ReprogramStep extends PureComponent {

  componentDidMount() {
    this.initDate();
  }

  setDate = d => {
    const { setData, merge } = this.props;
    const reprogDate = merge.getIn(paths.reprogDate);
    const newDate = reprogDate ?
      new Date(merge.getIn(paths.reprogDate)) : new Date();
    newDate.setFullYear(d.year(), d.month(), d.date());
    setData(paths.reprogDate, newDate);
  };

  setTime = e => {
    const { setData, merge } = this.props;
    const date = merge.getIn(paths.reprogDate, new Date());
    const numberPart = e.target.value.split(':');
    const newDate = new Date(date);
    newDate.setHours(
      Number.parseInt(numberPart[0], 10),
      Number.parseInt(numberPart[1], 10),
      0,
      0
    );
    setData(paths.reprogDate, newDate);
  };

  initDate = () => {
    const { setData, merge } = this.props;
    const date = merge.getIn(paths.reprogDate);
    const newReprogDate = date ?
      new Date(date) : roundedTime(new Date(), 15);
    setData(paths.reprogDate, newReprogDate);
  }

  render() {
    const { merge, classes, setData, title, auth } = this.props;
    const clientHasInformed = merge.getIn(paths.clientHasInformed);
    const reprogDate = merge.getIn(paths.reprogDate, new Date());
    return (
      !title && <Grid container spacing={2} justifyContent="center">
        <Grid
          container
          item
          spacing={2}
          xs={12}
          justifyContent="space-around"
          className={classes.marginTop}
        >
          <Grid item lg={6} md={6} xs={12} sm={12}>
            <DatePicker
              label={'Indiquez la date et l\'heure du nouveau rendez-vous'}
              startDate={reprogDate}
              onChange={this.setDate}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12} sm={12}>
            <TimePicker
              name="date"
              value={getTime(reprogDate)}
              onChange={this.setTime}
              labelRemoveSpace={true}
              size="100%"
              start={'07:00'}
              end={'21:00'}
            />
          </Grid>

        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <CustomRadioGroup
              grid
              xs={12}
              sm={12}
              label="Le client est-il prévenu de ce changement d'horaire ?"
              value={clientHasInformed !== null ?
                (+merge.getIn(paths.clientHasInformed)).toString() : null}
              path={paths.clientHasInformed}
              classes={classes}
              values={[
                { label: 'Oui', value: '1' },
                { label: 'Non', value: '0' },
              ]}
              setData={(path, value) => setData(path, !!+value)
              }
            />
          </Grid>
          {clientHasInformed !== null && !clientHasInformed ?
            <><Grid item xs={12}>
              <Typography
                children={'Il est fortement recommandé de ' +
              'contacter le client pour le tenir informé'}
              />
            </Grid> <Grid item xs={12}>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                aria-label="Call client"
                onClick={() => callClient(merge, auth, () => {
                  openUpdateStatusInter(this.props)
                })}
                fullWidth
                startIcon={<Phone />}
                style={{
                  fontSize: '0.8475rem',
                }}
              >
                  Client
              </Button>
            </Grid></> : ''}

        </Grid>
      </Grid>
    );
  }
}

export default withMediaQuery()(withStyles(ReprogramStep, styles));
