/* eslint-disable max-lines */
import api from '../api';
import notifSystem from '../notifSystem';
import { closeDialog, setDialog } from './dialog';
import store from '../store';
import Money from '../utils/Money';
import { DBON, DFARTI } from '@lba-dev/package.local-globals/docTypes';
import { signalInformation } from './artisan/api';
import { green, red } from '@mui/material/colors';
import {
  P_NONE,
  P_ACCEPTED,
} from '@lba-dev/package.local-globals/isValidStatus';
import { openWhatsApp } from '../utils/links';

export const paths = {
  isValid: ['proposition', 'isValid'],
  status: ['proposition', 'status'],
  reprogDate: ['proposition', 'reprogDate'],
  clientHasInformed: ['proposition', 'clientHasInformed'],
  rating: ['proposition', 'rating'],
  dropbox: ['dropbox'],
  finalPrice: ['finalPrice'],
  flexPrice: ['flexPrice'],
  announcedPriceMax: ['announcedPriceMax'],
  propsFinalPrice: ['proposition', 'finalPrice'],
  announcedPrice: ['announcedPrice'],
  onSitePayment: ['onSitePayment'],
  hidePrice: ['hidePrice'],
  interStatus: ['interStatus'],
  clientTel1: ['client', 'tel'],
  clientEmail1: ['client', 'mail'],
  clientName: ['client', 'name'],
  clientFirstName: ['client', 'firstname'],
  paymentMethod: ['proposition', 'paymentMethod'],
  matReturned: ['proposition', 'matReturned'],
  devisToDo: ['proposition', 'devisToDo'],
  tva: ['tva'],
  comments: ['comments'],
  descriptionMat: ['proposition', 'descriptionMat'],
  suppliesPrice: ['proposition', 'suppliesPrice'],
  priceTtc: ['priceTtc'],
  advancePayment: ['advancePayment'],
  demandePayment: ['date', 'demandePayment'],
  ratingComments: ['proposition', 'ratingComments'],
  cancelRaison: ['proposition', 'cancelRaison'],
  productTva: ['products', 'tva'],
  maxDate: ['maxDate'],
  propComments: ['proposition', 'comments'],
  transactionStatus: ['transactionStatus'],
  transactionUrl: ['transactionUrl'],
  isRegle: ['isRegle'],
  statusId: ['statusId'],
  advPayment: ['advPayment'],
  isFromDevis: ['isFromDevis']
};

export const sendPropositionApi = ({ id, action, merge, data }) =>
  api.propositions
    .custom(`${action}/${id}`)
    .patch(data)
    .then(() => {
      notifSystem.success('Message', 'Informations sauvegardées');
      const showRating = !['cancel', 'reprog'].includes(action) &&
        !merge.getIn(paths.rating);
      if (showRating) {
        openWhatsApp(`Bonjour ${merge.getIn(
          ['user', 'alias'])
        },\n\nJe viens de mettre à jour l'intervention n°${id}.`)
      } else if (action === 'cancel') {
        openWhatsApp(`Bonjour ${merge.getIn(
          ['user', 'alias'])
        },\n\nl'intervention n°${id} est annulée.`)
      }
      return {
        showRating:
          !['cancel', 'reprog'].includes(action) && !merge.getIn(paths.rating),
        title:
          merge.getIn(paths.rating) || ['cancel', 'reprog'].includes(action)
            ? 'Vos modifications ont bien été prise en compte'
            : '',
      };
    })
    .catch(() =>
      notifSystem.error('Erreur', 'Informations n\'ont pas été sauvegardées')
    );

const getMessageVerification = ({ action, merge }) => {
  let message = '';
  if (
    !['cancel', 'reprog'].includes(action) &&
    merge.getIn(paths.onSitePayment) &&
    !merge.getIn(paths.hidePrice)
  ) {
    const price = merge.getIn(paths.flexPrice)
      ? merge.getIn(paths.announcedPriceMax)
      : merge.getIn(paths.announcedPrice) || 0;
    const price10 = price + (price / 10);
    if (
      merge.getIn(paths.flexPrice) &&
      merge.getIn(paths.propsFinalPrice) >= price10
    ) {
      message = `Confirmez vous le montant de ${Money.toString(
        merge.getIn(paths.propsFinalPrice),
        true
      )}. Le montant annoncé au client était entre ${Money.toString(
        merge.getIn(paths.announcedPrice),
        true
      )} et ${Money.toString(merge.getIn(paths.announcedPriceMax), true)}`;
    }
    if (
      price &&
      !merge.getIn(paths.flexPrice) &&
      merge.getIn(paths.propsFinalPrice) >= price10
    ) {
      message = `Confirmez vous le montant de ${Money.toString(
        merge.getIn(paths.propsFinalPrice),
        true
      )}. Le montant annoncé au client était de ${Money.toString(price, true)}`;
    }
  }
  return message;
};

export function invoiceClient({
  merge,
  setData,
  deleteDoc,
  sendProposition,
  action = '',
  data,
}) {
  const lengthOfDocuments = merge
    .getIn(paths.dropbox)
    .filter((e) => e.get('type') === DBON).size;
  if (!lengthOfDocuments &&
    merge.get('onSitePayment') &&
    !merge.getIn(paths.isFromDevis)) {
    return store.dispatch(
      setDialog({
        name: 'SendBonInter',
        open: true,
        hideClose: true,
        dialogProps: {
          title: 'Vous n\'avez pas ajouter de facture client',
          middle: true,
          fullScreenOn: false,
          disableEscapeKeyDown: true,
          disableBackdropClick: true,
        },
        contentProps: {
          invoiceTitle: 'Ajouter Facture Client',
          photos: merge.getIn(paths.dropbox),
          setData: setData,
          deleteDoc: deleteDoc,
          statusColor: lengthOfDocuments ? green[500] : red[500],
          id: merge.get('id'),
          type: DBON,
        },
        actions: [
          {
            children: 'Retour',
            color: 'secondary',
            onClick: (validate, close) => {
              close();
            },
          },
          {
            children: 'Valider sans',
            color: 'primary',
            onClick: (validate, close) => {
              close();
              sendProposition(action, sendPropositionApi, 'inter', data);
            },
          },
        ],
      })
    );
  }
  return sendProposition(action, sendPropositionApi, 'inter', data);
}

export function invoiceMaterial({ merge, setData, deleteDoc }) {
  const lengthOfDocuments = merge
    .getIn(paths.dropbox)
    .filter((e) => e.get('type') === DFARTI).size;
  if (!lengthOfDocuments && merge.get('onSitePayment')) {
    return store.dispatch(
      setDialog({
        name: 'SendBonInter',
        open: true,
        hideClose: true,
        dialogProps: {
          title: 'Merci d\'ajouter la facture d\'achat du matériel utilisé',
          middle: true,
          fullScreenOn: false,
          disableEscapeKeyDown: true,
          disableBackdropClick: true,
        },
        contentProps: {
          invoiceTitle: 'Ajouter une facture fournisseur',
          photos: merge.getIn(paths.dropbox),
          setData: setData,
          deleteDoc: deleteDoc,
          statusColor: lengthOfDocuments ? green[500] : red[500],
          id: merge.get('id'),
          type: DFARTI,
        },
        actions: [
          {
            children: 'Fermer',
            color: 'primary',
            onClick: (validate, close) => {
              close();
            },
          },
        ],
      })
    );
  }
}

const getMessageVerificationPayment = ({ merge }) => {
  let message = '';

  const price =
    merge.getIn(paths.finalPrice) || merge.getIn(paths.announcedPrice);
  const price100 = price + price;
  if (price && merge.getIn(paths.propsFinalPrice) >= price100) {
    message = `Confirmez vous le montant de ${Money.toString(
      merge.getIn(paths.propsFinalPrice),
      true
    )}. Le montant annoncé au client était de ${Money.toString(price, true)}`;
  }
  return message;
};

export const verification = (data, key, cb) => {
  const message = {
    payment: getMessageVerificationPayment,
    inter: getMessageVerification,
  }[key](data);
  return message
    ? new Promise((res) =>
      store.dispatch(
        setDialog({
          name: 'VerficationProp',
          open: true,
          hideClose: true,
          dialogProps: {
            title: 'Information',
            middle: true,
            fullScreenOn: false,
          },
          contentProps: { message },
          actions: [
            {
              children: 'Non, modifier',
              color: 'secondary',
              onClick: (r, close) => {
                close();
                res(false);
              },
            },
            {
              children: 'Valider',
              color: 'primary',
              onClick: (validate, close) => {
                close();
                if (validate) {
                  return cb().then((e) => res(e));
                }
                return res(false);
              },
            },
          ],
        })
      )
    )
    : cb();
};

export const getArtisanInterventions = (id, query) =>
  api.interventions
    .custom(`artisan/${id}`)
    .get(query)
    .then((res) => res.body().data())
    .catch(() =>
      notifSystem.error('Erreur', 'La demande n\'a pu être envoyée.')
    );

export const getArtisanPropositions = (query) =>
  api.propositions
    .custom(`${query.filterData}`)
    .get(query)
    .then((res) => res.body().map((e) => e.data()))
    .catch(() =>
      notifSystem.error('Erreur', 'Les propositions n\'ont pu être récupérées.')
    );

export const getIntervention = (body) =>
  api.interventions
    .custom('checkOS')
    .post(body)
    .then((res) => res.body().data())
    .catch(() =>
      notifSystem.error('Erreur', 'La demande n\'a pu être envoyée.')
    );

export const getArtisan = () =>
  api.artisans
    .custom('')
    .get()
    .then((res) => res.body().data())
    .catch((e) =>
      notifSystem.error('Erreur', e.response ? e.response.data : e.message)
    );

export const getOneProposition = (body) =>
  api.propositions
    .custom(`${body}`)
    .get()
    .then((res) => res.body().data())
    .catch((e) => {
      store.dispatch(
        setDialog({
          name: 'InformPopUp',
          open: true,
          dialogProps: {
            title: 'Message',
            middle: true,
            fullScreenOn: false,
          },
          contentProps: {
            timeout: 2000,
            location: '/missions/ongoing',
            message: `${
              e.response && e.response.data === 5
                ? 'La mission n\'est plus d\'actualité'
                : 'La mission n\'a pas pu être récupérée'
            }`,
            callback: () => {
              store.dispatch(closeDialog());
            },
          },
        })
      );
    });
/**
 * Call paiements CB api
 *
 * @param {String[]} mail
 * @param {String[]} tel
 * @param {String} montant - price in cents
 * @param {String} id - inter id
 */
export const sendPaymentCBArtisanClient = (data, cb) =>
  new Promise((resolve) =>
    api.paiements
      .custom('demandePayment')
      .post(data)
      .then(() => {
        if (cb) {
          cb();
        }
        notifSystem.success(
          'Message',
          'Un lien de paiement CB a bien été envoyé'
        );
        resolve();
      })
      .catch((e) => {
        notifSystem.error('Erreur', e.response ? e.response.data : e.message);
      })
  );

export const getArtisanPaiements = (inter) =>
  api.paiements
    .custom('artisan')
    .get({
      interId: inter.id,
    })
    .then((res) => res.body().map((e) => e.data()))
    .catch((e) =>
      notifSystem.error(e.name, e.response ? e.response.data : e.message)
    );

export const getAbsence = (query) =>
  api.artisans
    .custom('getArtisanAbsence')
    .get(query)
    .then((res) => res.body().map((e) => e.data()))
    .catch(() => notifSystem.error('Erreur', 'ERR'));

export const clickAccept = (interId) => {
  if (interId) {
    api.propositions
      .custom(`accept/${interId}`)
      .patch()
      .then(() => {
        store.dispatch(
          setDialog({
            name: 'InformPopUp',
            open: true,
            dialogProps: {
              title: 'Vous êtes intéressé par cette mission',
              middle: true,
              fullScreenOn: false,
            },
            contentProps: {
              timeout: 5000,
              location: '/missions/news',
              message:
                'Votre réponse a été transmise à la ' +
                'personne en charge de l\'intervention',
              callback: () => {
                openWhatsApp('Je suis disponible');
                store.dispatch(closeDialog());
              },
            },
          })
        );
      })
      .catch((e) =>
        notifSystem.error(e.name, e.response ? e.response.data : e.message)
      );
  }
};

export const declineReasonsDialog = (interId = '') =>
  store.dispatch(
    setDialog({
      name: 'DeclineReasons',
      open: true,
      hideClose: true,
      dialogProps: {
        title: `Pour quelle raison vous n'êtes 
        pas intéressé par l'intervention ?`,
        fullScreenOn: 'sm',
        disableEscapeKeyDown: true,
        onBackdropPress: true,
      },
      contentProps: {
        newDispo: null,
      },
      actions: [
        {
          children: 'Annuler',
          color: 'secondary',
          onClick: (validate, close) => {
            close();
          },
        },
        {
          children: 'Valider',
          color: 'primary',
          onClick: ({ declineReason, declineMessage, newDispo }, close) => {
            if (!declineReason) {
              return notifSystem.error(
                'Erreur',
                'Veuillez séléctionner une raison'
              );
            } else if (declineReason === 'Autres' && !declineMessage) {
              return notifSystem.error('Erreur', 'Veuillez saisir une raison');
            } else if (declineReason === 'Indisponible' && newDispo === null) {
              return notifSystem.error(
                'Erreur',
                'Veuillez choisir une réponse'
              );
            }

            api.propositions
              .custom(`decline/${interId}`)
              .patch({
                declineReason: declineMessage
                  ? `${declineReason}: ${declineMessage}`
                  : declineReason,
                newDispo,
              })
              .then(() => {
                store.dispatch(
                  setDialog({
                    name: 'InformPopUp',
                    open: true,
                    dialogProps: {
                      title: 'Opération réussie',
                      middle: true,
                      fullScreenOn: false,
                    },
                    contentProps: {
                      timeout: 2000,
                      location: '/missions/news',
                      message: 'Nous avons bien pris en compte votre choix',
                      callback: () => {
                        openWhatsApp('Je ne suis pas disponible');
                        close();
                        store.dispatch(closeDialog());
                      },
                    },
                  })
                );
              })
              .catch((e) =>
                notifSystem.error(
                  e.name,
                  e.response ? e.response.data : e.message
                )
              );
          },
        },
      ],
    })
  );

export const assistanceDialog = (inter) =>
  store.dispatch(
    setDialog({
      name: 'Assistance',
      open: true,
      hideClose: true,
      dialogProps: {
        title: 'Comment voulez-vous nous contacter ?',
        middle: true,
        fullScreenOn: false,
        disableEscapeKeyDown: true,
        onBackdropPress: true,
      },
      contentProps: {
        inter,
      },
      actions: [
        {
          children: 'Annuler',
          color: 'secondary',
          onClick: (validate, close) => {
            close();
          },
        },
      ],
    })
  );

export const paiementDialog = ({
  setData,
  artisanId,
  mail,
  tel,
  montant,
  id,
  to,
  merge,
  checkData,
}) => {
  store.dispatch(
    setDialog({
      name: 'Paiement',
      open: true,
      hideClose: true,
      dialogProps: {
        title: 'Paiement par carte bancaire',
        middle: true,
        fullScreenOn: false,
        disableEscapeKeyDown: true,
        onBackdropPress: true,
      },
      contentProps: {
        setData,
        artisanId,
        mail,
        tel,
        montant,
        id,
        to,
        merge,
        checkData,
      },
      actions: [
        {
          children: 'Retour',
          color: 'secondary',
          onClick: (validate, close) => {
            close();
          },
        },
      ],
    })
  );
};

export const callClient = (merge, auth, cb = f => f) => {
  store.dispatch(
    setDialog({
      name: 'VerficationProp',
      open: true,
      hideClose: true,
      dialogProps: {
        title: 'Information',
        middle: true,
        fullScreenOn: false,
        disableEscapeKeyDown: true,
        onBackdropPress: true,
      },
      contentProps: {
        message:
          'En cliquant sur "confirmer",' +
          ' un appel automatique sera lancé.' +
          ' Veuillez y répondre pour être mis en relation avec le client.',
      },
      actions: [
        {
          children: 'Annuler',
          color: 'secondary',
          onClick: (validate, close) => {
            close();
            cb();
          },
        },
        {
          children: 'Confirmer',
          color: 'primary',
          onClick: (validate, close) => {
            if (merge.get('client') && merge.get('id')) {
              api
                .custom('communication/abPlusCb')
                .post({
                  telArtisan1: auth.tel1,
                  telClient1: merge.getIn(paths.clientTel1, ''),
                  numeroOS: merge.get('id').toString(),
                  send: 0,
                })
                .then(() => {
                  notifSystem.success(
                    'Opération réussie',
                    'Vous allez être mis en relation avec le client'
                  );
                  close();
                })
                .catch((e) =>
                  notifSystem.error(
                    e.name,
                    e.response ? e.response.data : e.message
                  )
                ).finally(cb);
            } else {
              cb();
            }
          },
        },
      ],
    })
  );
};

export const contactMajDialog = (interId = '', navigate) =>
  store.dispatch(
    setDialog({
      name: 'VerficationProp',
      open: true,
      hideClose: true,
      dialogProps: {
        title: 'Information',
        middle: true,
        fullScreenOn: false,
        disableEscapeKeyDown: true,
        onBackdropPress: true,
      },
      contentProps: {
        message:
          'Cette mise à jour a déjà été traitée par notre service support.',
      },
      actions: [
        {
          children: 'Problème ? être rappelé',
          color: 'secondary',
          onClick: (validate, close) => {
            signalInformation(
              `Problème de mise à jour avec l'os ${interId}`,
              () => {
                close();
                navigate('/');
              },
              true
            );
          },
        },
        {
          children: 'OK',
          color: 'primary',
          onClick: (r, close) => {
            close();
            navigate('/');
          },
        },
      ],
    })
  );

export const defaultValues = (data) => {
  const dropbox = (data.photos || []).map((e) => ({
    fileName: e.name,
    link: e.url,
    type: e.type,
  }));
  return {
    finalPrice: data.priceHT,
    title: data.title,
    priceTtc: data.price,
    dropbox,
    announcedPrice: data.announcedPrice,
    id: data.id,
    _id: data._id,
    date: {
      intervention: data.date,
      demandePayment: data.dateDemandePayment,
      dateString: data.dateString,
      attestSent: data.attestSent,
    },
    client: data.client || {
      ...(data.fullAddress && {
        fullAddress: data.fullAddress,
      }),
      ...(data.displayedAddress && {
        displayedAddress: data.displayedAddress,
      }),
      ...(data.clientPartName && {
        partName: data.clientPartName,
      }),
    },
    infoDesc: {
      information: data.information,
      context: data.context,
      desc: data.desc,
      toBeSelledOnSite: data.toBeSelledOnSite,
      infoClient: data.infoClient,
      infoTech: data.infoTech
    },
    interStatus: data.interStatus,
    proposition: {
      isValid: data.isValid,
      status: [P_NONE, P_ACCEPTED].includes(data.isValid)
        ? data.propositionStatus
        : null,
      finalPrice: data.propFinalPrice,
      matReturned: data.propositionMatReturned || 0,
      comments: '',
      paymentMethod: data.propositionPaymentMethod || 0,
      devisToDo: data.propositionDevisToDo || 0,
      descriptionMat: data.propositionDescriptionMat || '',
      suppliesPrice: data.propositionSuppliesPrice || 0,
      rating: null,
      ratingComments: '',
      clientHasInformed: null,
    },
    tva: data.tva,
    onSitePayment: data.onSitePayment,
    user: data.user || null,
    products: data.products || {},
    advancePayment: data.advancePaymentAmount || 0,
    artisan: data.currentArtisan,
    isRegle: data.isRegle,
    hidePrice: data.hidePrice,
    status: data.status,
    statusId: data.statusId,
    flexPrice: data.flexPrice,
    announcedPriceMax: data.announcedPriceMax,
    maxDate: data.maxDate,
    distance: data.distance,
    showPrice: data.showPrice,
    showPriceTtc: data.showPriceTtc,
    login: {
      ajout: data.loginAjout,
    },
    currentArtisan: {
      ...(data.currentArtisanTel && {
        tel1: data.currentArtisanTel,
      }),
    },
    transactionStatus: data.transactionStatus,
    transactionUrl: data.transactionUrl,
    advPayment: data.advPayment || {},
    remuneration: data.remuneration,
    isFromDevis: data.isFromDevis
  };
};
