import React, { useEffect, useState, useRef } from 'react';
import MainLayout from '../components/Main/MainLayout';
import {
  genMarkersInfos,
  genMarkers,
  moveMarker
} from '../actions/map';
import Content from '../components/Maps/Content';
import { useParams } from 'react-router';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import ws from '../ws';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import company from '@lba-dev/package.local-globals/company';
import {
  I_ENC,
  I_VRF,
  data as statusData
} from '@lba-dev/package.local-globals/iStatus'
const useStyles = makeStyles()((theme) => ({
  grid: {
    height: '90%',
  },
  backdrop: {
    background: 'rgba(255,255,255,0.75)',
    zIndex: 1301,
    display: 'flex',
  },
  title: {
    margin: theme.spacing(2),
  }
})
);

const defaultState = {
  markers: [],
  loading: true,
  statusInter: null,
  price: 0,
  distance: null,
  duration: null,
  normalRoute: {},
  artisanRoute: {},
  newArtisanPoint: [],
  error: ''
};

function Geolocation() {
  const [state, setState] = useState(defaultState);
  const { classes } = useStyles();
  const params = useParams();

  const goToWebsite = () => {
    setTimeout(() => {
      window.open(company.website);
    }, 3000);
    return true;
  };

  const setData = (data) => {
    setState({
      ...state,
      ...data
    });
  };

  const stateRef = useRef(state);

  useEffect(() => {
    stateRef.current = state;
  });

  useEffect(() => {
    setState({
      ...state,
      loading: true
    });
    genMarkersInfos({ params, stateRef })
      .then(data => data && setState({ ...state, ...data }));
    ws.on('update_artisanCoordinates',
      (coordinates) => genMarkersInfos({ coordinates, params, stateRef })
        .then(data => data && setState({ ...state, ...data })));
  }, []);

  const {
    statusInter,
    markers,
    loading,
    price,
    distance,
    normalRoute,
    duration,
    artisanRoute,
    error
  } = state;

  return (
    (loading &&
    <Backdrop className={classes.backdrop} open>
      <CircularProgress size={50} color="secondary" />
      <h3 className={classes.title}
        children={loading.message || 'Chargement en cours...'}
      />
    </Backdrop>) ||
    ([I_ENC, I_VRF].includes(statusInter) ?
      <MainLayout title="Localisation de mon artisan" key={0}>
        <Content
          normalRoute={normalRoute}
          artisanRoute={artisanRoute}
          genMarkers={genMarkers}
          moveMarker={moveMarker}
          setData={setData}
          error= {error}
        />
        <Grid>
          <Typography variant="subtitle1">
            {distance && duration !== 0 ?
              `L'artisan se trouve à ${distance} KM ` +
            `et arrivera dans ${duration} !` : 'L\'artisan est tout proche !'}
          </Typography>
          <Typography variant="subtitle1">
            Votre adresse: {markers?.find(e =>
              e.properties.status === 'client')?.address}
          </Typography>
          <Typography variant="subtitle1">
            Statut de l'intervention: {statusData
              .find(e => e._id === statusInter).name}
          </Typography>
          <Typography variant="subtitle1">
            Prix de l'intervention: {price}€
          </Typography>
        </Grid>
      </MainLayout>
      :
      !loading && goToWebsite() && <Grid
        className={classes.grid}
        container
        spacing={2}
        justify="center"
        alignContent="center"
      >
        <Grid
          item
          xs={12}
          lg={12}
          sm={12}
          children={
            <Typography
              className={classes.text}
              align="center"
              variant="h3"
              gutterBottom
              children="Intervention terminée"
            />
          }
        />
      </Grid>)
  );
}

export default Geolocation;
