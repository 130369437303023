
import { createTheme } from '@mui/material/styles';
import { red, blue, pink } from '@mui/material/colors';

export const login = createTheme({
  palette: {
    primary: {
      main: blue[500]
    },
    secondary: {
      main: pink[500]
    },
    error: red
  },
});

export default login
