import React, { Component } from 'react';

import { moment } from '@lba-dev/package.local-globals/moment';
import { fr } from 'date-fns/locale';

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: this.props.startDate,
    };
  }

  componentDidUpdate(prevProps) {
    if (!moment(prevProps.startDate).isSame(this.props.startDate)) {
      this.setState({
        from: this.props.startDate
      });
    }
  }

  handleSelect = (data) => {
    const { handleRange, mode } = this.props;
    let update = { from: data };
    if (mode === 'range') {
      update = {
        from: data?.from || null,
        to: data?.to || null
      };
    }
    this.setState(update, () => {
      if (mode === 'range') {
        handleRange(update);
      }
    });
  }

  handleFromDayChange = (from) => {
    this.setState({ from });
    this.props.onChange(moment(from), this.props.stateName);
  }

  handleFromKeyPress = (e) => {
    if (e.key === 'Enter'){
      this.props.onChange(moment(this.state.from), this.props.stateName);
    }
  }

  render() {
    const { from, to } = this.state;
    const {
      before,
      beforeDate,
      disabledDays,
      mode = 'single'
    } = this.props;
    const Picker = <DayPicker
      mode={mode}
      locale={fr}
      showOutsideDays
      selected={mode === 'range' ? { from, to } : from}
      disabled={
        disabledDays ||
        (before ? { } : { before: beforeDate || new Date() })
      }
      displayMonth={from}
      day={from}
      fixedWeeks
      onSelect={this.handleSelect}
      numberOfMonths={1}
      onDayClick={(e) => mode === 'single' && this.handleFromDayChange(e)}
      {...this.props}
    />;
    return Picker;
  }
}

export default DatePicker;
