import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import SignPage from './SignPage';
import notifSystem from '../../notifSystem';
import api from '../../api';
import RenderHTML from '../../views/RenderHTML';

const Components = ({ selected, data, signature, ...props }) =>
  ({
    sign: <SignPage {...props} />,
    visuel: (
      <RenderHTML
        callback={(e) => e}
        data={{ signature }}
        type={`artisans/${data._id}/contrat`}
        {...props}
        renderFile
      />
    ),
    '': null,
  }[selected]);

const styles = {
  container: {
    height: '98%',
    marginTop: 3,
    width: 'calc(100% + 4px)'
  },
  signContainer: {
    height: 'calc(100% - 60px)',
  },
  displayer: {
    height: '93%',
    padding: '0 4px!important',
    position: 'relative',
  },
  validButton: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: '#0e8813'
    },
  },
  validGrid: {
    padding: '6px 10px!important',
    height: 48
  },
};


class SignContrat extends PureComponent {
  state = {
    signature: '',
    selected: 'visuel'
  }

  getSignature = (signature) => {
    this.setState({
      signature,
      selected: 'visuel',
    });
  }

  sendContrat = () => {
    const { data: artisan } = this.props;
    api.artisans.custom(`${artisan._id}/contrat`)
      .post({ data: {
        signature: this.state.signature,
      } })
      .catch(() => {
        notifSystem.error(
          'Erreur',
          'Le contrat n\'a pas été envoyé'
        );
      });
  }

  setPreviewDialog = (open) => {
    this.setState({
      previewDialog: open
    });
  }

  setSelected = (value) => {
    this.setState({
      selected: value,
    });
  }

  render() {
    const { data = {}, classes, collection, navigate } = this.props;
    const { signature, selected } = this.state;

    return data && <Grid container spacing={1}
      className={classes.container}>
      <Grid item xs={12} className={classes.displayer}>
        <Components
          selected={selected}
          data={data}
          collection={collection}
          signature={signature}
          getSignature={this.getSignature}
          classes={{ container: classes.signContainer }}
        />
      </Grid>
      <Grid item xs={12} className={classes.validGrid}>
        <Button
          color='secondary'
          disabled={selected === 'sign'}
          fullWidth
          className={signature ? classes.validButton : null}
          variant='contained'
          onClick={() => signature ?
            navigate(-1) :
            this.setSelected('sign')}
        >
          {signature ? 'Quitter' : 'Signer'}
        </Button>
      </Grid>
    </Grid>;
  }
}

export default withStyles(SignContrat, styles);
